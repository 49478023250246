import React from 'react';

const MyFooter = () => {
    return(
        <div>
            <p>Footer</p>  
        </div>
    );
}

export default MyFooter;