import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import Auxiliary from './../../../hoc/Auxiliary';

import "./Home.scss";

const Home = () => {
    return(
        <Auxiliary>
            <Container fluid style={{ padding:0 }}>
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/image_set_default.jpg"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072823_1280.jpg"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>    
            </Container>
        </Auxiliary>
    )
}

export default Home;