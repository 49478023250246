import React from 'react';
import Auxiliary from './../hoc/Auxiliary';
import Content from './pages/Content';
import MyNavbar from './components/MyNavbar';
import MyFooter from './components/MyFooter';
import  { Container, Row } from 'react-bootstrap';

const Layout = () => {

    return(
        <div>
            <Auxiliary>
            <Container fluid>
                <Row>
                    <MyNavbar />
                </Row>
                <Row style={{ marginTop: '75px' }}>
                    <Content />
                </Row>
                <Row>
                    <MyFooter />
                </Row>
            </Container>
            </Auxiliary>
        </div>
    )
}

export default Layout;