import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';

import { Container, Navbar, Nav,NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

import './MyNavbar.scss'

const MyNavbar = () => {
    return(
        <Auxiliary>
            <Container fluid style={{ padding:0 }}>
                <Navbar bg="light" expand="lg" fixed="top">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRiw3n8IEJyhLMkB1j3xNOWehTEUgIa7wbowkb-10VBHVoObu4D&usqp=CAU"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span className="title-font title-color">Travel INDIA Guru</span>
                </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        <Nav.Link href="/place">Place</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Form inline>
                            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                            <Button variant="outline-success">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </Auxiliary>
    )
}

export default MyNavbar;