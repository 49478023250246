import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Home from './Home/Home';
import About from './About/About';
import Place from './Places/Place';

const Content = () => {
    return(
        <Router>
          <Switch>
            <Route path="/place/:id" component={Place}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </Router>
    )
}

export default Content;