import React from 'react';
import Auxiliary from './hoc/Auxiliary';
import Layout from './Layout/Layout';

function App() {
  return (
    <div className="App">
      <Auxiliary>
        <Layout />
      </Auxiliary>
    </div>
  );
}

export default App;
