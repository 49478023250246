import React, { Component} from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import { FaStar, FaStarHalf, FaSearchLocation,FaPhone,FaClock } from 'react-icons/fa';

import Auxiliary from '../../../hoc/Auxiliary';


class Place extends Component {

    state = {
        place: {
            sl: null,
            slug: null,
            title: null,
            city: null,
            district: null,
            state: null,
            postal: null,
            reviews_count: null,
            rating: null,
            address: null,
            map: null,
            geo_lat: null,
            geo_lng: null,
            place_type: null,
            phone_number: null,
            int_phone_number: null,
            website: null,
            timings: null,
            wiki_page_url: null,
            wiki_summary: null
        },
        image: null
    }

    componentDidMount () {
        // const { handle } = this.props.match.params

        const place = this.props.match.params.id
        console.log(place);
        // this.setState(() => ({place}))

        fetch(`https://tig-api.now.sh/place/ka/${place}`)
            .then(async response => {
                const data = await response.json();
                console.log('placeData',data);   
                this.setState(() => ({ place: data.data }))
            })

        fetch(`https://tig-api.now.sh/images/ka/${place}/1/1`)
            .then(async response => {
                const image = await response.json();
                console.log('Image',image);   
                this.setState(() => ({ ...this.state,image: image.data }))
            })
        
    }
    render() {
        return(
            <Auxiliary>
                <Container fluid style={{ margin: '10px', backgroundColor: 'red'}}>
                    <Row>
                        <Col xs md lg="9" className="d-flex" style={{padding:0}}>
                            <Card style={{ width: '100%'}}>
                                <Card.Body>
                                    <Card.Title><b>{this.state.place.title}</b></Card.Title>
                                    <Card.Text>
                                        <p></p>
                                        <img src={this.state.image}
                                            alt={this.state.title} width="450"/>
                                        <p></p>
                                    </Card.Text>
                                    <Button variant="primary">Go somewhere</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs md lg="3" className="d-flex" style={{padding:0}}>
                            <Card style={{ width: '100%'}}>
                                <Card.Body>
                                    <Card.Title>Details</Card.Title>
                                    <Card.Text>
                                        <Row>
                                            <Col>Rating:</Col>
                                            <Col><FaStar /><FaStar /><FaStar /><FaStarHalf /></Col>
                                        </Row> <br />
                                        <Row>
                                            <Col>Address: <FaSearchLocation /></Col>
                                            <Col>Hare Krishna Hill, Chord Rd, 1st R Block, Rajaji Nagar, Bengaluru, Karnataka 560010, India</Col>
                                        </Row> <br />
                                        <Row>
                                            <Col>Phone: <FaPhone /></Col>
                                            <Col>080 2347 1956</Col>
                                        </Row> <br />
                                        <Row>
                                            <Col>Website:</Col>
                                            <Col>https://www.iskconbangalore.org/</Col>
                                        </Row> <br />
                                        <Row>
                                            <Col>Timings: <FaClock /></Col>
                                            <Col><p>Monday, Tuesday, Wednesday, Thursday, Friday
7:15 AM – 1:00 PM, 4:00 – 8:20 PM</p><p>Saturday, Sunday
7:15 AM – 8:30 PM</p></Col>
                                        </Row>
                                    </Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Auxiliary>
        );
    }
}

export default Place;